export default [
    {
        name: 'cdi',
        displayName: 'CDI'
    },
    {
        name: 'bovespa',
        displayName: 'Bovespa'
    },
    {
        name: 'dolar',
        displayName: 'Dólar'
    },
    {
        name: 'euro',
        displayName: 'Euro'
    }
];